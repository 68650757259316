<template>
  <div v-if="vectorLayers.length > 0">
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-divider class="pb-4" />
    <div class="d-flex justify-space-between">
      <meteogram-button
        v-if="isMeteogram"
        :meteogram-enabled="meteogramEnabled"
        :location-name="map.locationName"
        :meteogram-obj="meteogramObj"
        :map-enabled="mapEnabled"
        @toggle-meteogram-btn="$emit('toggle-meteogram-btn', $event)"
        @toggle-map-btn="toggleMapBtn(selectedVectorLayer)"
      />
      <strong v-else>
        {{ $t('ServiceCard.vectorLayer') }}
      </strong>
      <v-btn
        v-if="($route.params.id === 'Real-Time' || $route.params.id === 'Forecast') && showSelect"
        elevation="2"
        icon
        :disabled="disableRefreshButton"
        @click="initRefresh()"
      >
        <v-icon :class="rotateEffect">
          mdi-refresh
        </v-icon>
      </v-btn>
    </div>
    <div v-if="showSelect">
      <v-row
        v-if="$route.params.id === 'Real-Time' || $route.params.id === 'Forecast'"
        class="pb-4 ma-0"
      >
        <v-col
          cols="10"
          md="11"
          class="pl-0"
        >
          <v-select
            v-model="selectedVectorLayer"
            :disabled="isRefreshing"
            :attach="`.card${serviceCardId}`"
            :items="vectorLayers.filter(item => !item.layer_name.includes('ignore'))"
            :item-text="item =>`${$t(`${item.ws_name}:${item.layer_name}_serviceID:${serviceCardId}.label`)}${$t(`${item.ws_name}:${item.layer_name}_serviceID:${serviceCardId}.udm`)}`"
            item-color="RMprimary"
            color="RMprimary"
            return-object
            solo
            :class="`card${serviceCardId} pt-4`"
            @change="$emit('reset-min-max-step'); removePreviousLayer(); openVectorLayer(selectedVectorLayer); googleTag();"
          />
        </v-col>
        <v-col
          cols="2"
          md="1"
          class="pl-0"
        >
          <v-icon
            class="mt-7"
            :disabled="Object.keys(selectedVectorLayer).length === 0 || isRefreshing"
            @click="removeVectorLayer(selectedVectorLayer); renderPlayer = false"
          >
            mdi-delete-forever
          </v-icon>
        </v-col>
      </v-row>
      <v-row
        v-else
        class="ma-0"
      >
        <v-col
          cols="10"
          md="11"
          class="pl-0"
        >
          <v-select
            v-model="selectedVectorLayer"
            :attach="`.card${serviceCardId}`"
            :items="vectorLayers.filter(item => !item.layer_name.includes('ignore'))"
            :item-text="item =>`${$t(`${item.ws_name}:${item.layer_name}_serviceID:${serviceCardId}.label`)}${$t(`${item.ws_name}:${item.layer_name}_serviceID:${serviceCardId}.udm`)}`"
            item-color="RMprimary"
            color="RMprimary"
            return-object
            solo
            :class="`card${serviceCardId} pt-4`"
          />
        </v-col>
        <v-col
          cols="2"
          md="1"
          class="pl-0"
        >
          <v-icon
            class="mt-7"
            :disabled="Object.keys(selectedVectorLayer).length === 0"
            @click="removeVectorLayer(selectedVectorLayer)"
          >
            mdi-delete-forever
          </v-icon>
        </v-col>
      </v-row>
      <div v-if="$route.params.id === 'Archive' && !overlay">
        <service-card-archive
          v-if="renderArchiveDate"
          :selected-vector-layer="selectedVectorLayer"
          :vector-layers="vectorLayers"
          :service-card-id="serviceCardId"
          :animation-limit-range-array="animationLimitRangeArray"
          :animation-limit-range-array-policy="animationLimitRangeArrayPolicy"
          :animation-step-date-type="animationStepDateType"
          :animation-step="animationStep"
          :min-step="minStep"
          :max-step="maxStep"
          :is-animation="isAnimation"
          :is-table="isTable"
          :is-graph="isGraph"
          :service-name="serviceName"
          :layer-type="'VECTOR'"
          :animation-time-array="animationTimeArray"
          :animation-start-index="animationStartIndex"
          :animation-empty-steps="animationEmptySteps"
          :no-features-found="noFeaturesFound"
          :no-wmts-features-found="noWmtsFeaturesFound"
          :table-chart-range-date="tableChartRangeDate"
          @set-animation-steps="setAnimationSteps($event)"
          @no-vector-features-found="noVectorFeaturesFound($event)"
          @google-tag="googleTag()"
        />
      </div>
      <div v-if="$route.params.id === 'Forecast' && renderPlayer">
        <service-card-forecast
          :selected-vector-layer="selectedVectorLayer"
          :service-card-id="serviceCardId"
          :animation-limit-range-array="animationLimitRangeArray"
          :animation-limit-range-array-policy="animationLimitRangeArrayPolicy"
          :animation-step-date-type="animationStepDateType"
          :animation-step="animationStep"
          :min-step="minStep"
          :max-step="maxStep"
          :is-animation="isAnimation"
          :overlay="overlay"
          :service-name="serviceName"
          :layer-type="'VECTOR'"
          :animation-time-array="animationTimeArray"
          :animation-start-index="animationStartIndex"
          :animation-empty-steps="animationEmptySteps"
          :no-features-found="noFeaturesFound"
          :no-wmts-features-found="noWmtsFeaturesFound"
          :table-chart-range-date="tableChartRangeDate"
          :check-policy-limits="checkPolicyLimits"
          @set-animation-steps="setAnimationSteps($event)"
          @reset-min-max-step="$emit('reset-min-max-step')"
          @no-vector-features-found="noVectorFeaturesFound($event)"
        />
      </div>
      <div v-if="$route.params.id === 'Real-Time'">
        <animation-player
          v-if="renderPlayer"
          :selected-vector-layer="selectedVectorLayer"
          :service-card-id="serviceCardId"
          :animation-limit-range-array="animationLimitRangeArray"
          :animation-limit-range-array-policy="animationLimitRangeArrayPolicy"
          :animation-step-date-type="animationStepDateType"
          :animation-step="animationStep"
          :min-step="minStep"
          :max-step="maxStep"
          :is-animation="isAnimation"
          :overlay="overlay"
          :service-name="serviceName"
          :layer-type="'VECTOR'"
          :animation-time-array="animationTimeArray"
          :animation-start-index="animationStartIndex"
          :animation-empty-steps="animationEmptySteps"
          :no-features-found="noFeaturesFound"
          :no-wmts-features-found="noWmtsFeaturesFound"
          @no-vector-features-found="noVectorFeaturesFound($event)"
        />
      </div>
      <vector-layer-popup
        v-if="($route.params.id === 'Forecast' || $route.params.id === 'Real-Time') && renderPlayer"
        :selected-vector-layer="selectedVectorLayer"
        :vector-layers="vectorLayers"
        :service-card-id="serviceCardId"
        :animation-step-date-type="animationStepDateType"
        :animation-step="animationStep"
        :min-step="minStep"
        :max-step="maxStep"
        :service-name="serviceName"
        :is-table="isTable"
        :is-animation="isAnimation"
        :table-chart-range-date="tableChartRangeDate"
        :is-daily-data="dailyData"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { toLonLat } from 'ol/proj';
import { getFormattedDate, updatedDateWithTimeStep, timeToMilliseconds, dateUnit } from '@/utils.js';

export default {
  components: {
    ServiceCardForecast: () => import('@/components/layout/ServiceCardForecast'),
    AnimationPlayer: () => import('@/components/utils/AnimationPlayer'),
    ServiceCardArchive: () => import('@/components/layout/ServiceCardArchive'),
    VectorLayerPopup: () => import('@/components/utils/VectorLayerPopup'),
    MeteogramButton: () => import('@/components/utils/MeteogramButton')
  },
  props: {
    serviceCardId: {
      type: String,
      default: ''
    },
    vectorLayers: {
      type: Array,
      default: () => []
    },
    layerToSelectVector: {
      type: Object,
      default: () => {}
    },
    animationStepDateType: {
      type: String,
      default: ''
    },
    animationStep: {
      type: String,
      default: ''
    },
    animationLimitDateType: {
      type: String,
      default: ''
    },
    animationLimitFuture: {
      type: String,
      default: ''
    },
    animationLimitPast: {
      type: String,
      default: ''
    },
    refreshIntervalStep: {
      type: String,
      default: ''
    },
    refreshIntervalStepType: {
      type: String,
      default: ''
    },
    serviceName: {
      type: String,
      default: ''
    },
    isAnimation: {
      type: Boolean,
      default: false
    },
    isTable: {
      type: Boolean,
      default: false
    },
    isGraph: {
      type: Boolean,
      default: false
    },
    isMeteogram: {
      type: Boolean,
      default: false
    },
    meteogramEnabled: {
      type: Boolean,
      default: false
    },
    meteogramObj: {
      type: Object,
      default: () => {}
    },
    minStep: {
      type: Date,
      default: null
    },
    maxStep: {
      type: Date,
      default: null
    },
    rasterLayers: {
      type: Array,
      default: () => []
    },
    animationLimitRangeArray: {
      type: Array,
      default: () => []
    },
    animationLimitRangeArrayPolicy: {
      type: Array,
      default: () => []
    },
    policyLimits: {
      type: Object,
      default: () => {}
    },
    checkPolicyLimits: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      overlay: false,
      renderArchiveDate: true,
      isRefreshing: false,
      refreshBtnDisabled: false,
      renderPlayer: false,
      noFeaturesFound: false,
      noWmtsFeaturesFound: false,
      mapEnabled: false,
      selectedVectorLayer: {},
      animationTimeArray: [],
      animationEmptySteps: [],
      animationStartIndex: 0,
      tableChartRangeDate: '',
      rotateEffect: ''
    };
  },
  computed: {
    ...mapState(['map', 'app_user', 'table_chart', 'geoserver_data']),
    disableRefreshButton() {
      const activeLayer = this.map.OLmap.getLayers().getArray().slice(1).some(layer => layer.values_.service_id === this.serviceCardId && layer.values_.layer_type !== 'METEOGRAM');

      return !activeLayer || this.isRefreshing || this.refreshBtnDisabled;
    },
    dailyData() {
      return this.animationStepDateType === 'dynamic_months' || this.animationStepDateType === 'dynamic_days' || (this.animationStepDateType === 'dynamic_hours' && this.animationStep >= 24);
    },
    showSelect() {
      return this.mapEnabled || !this.isMeteogram;
    }
  },
  watch: {
    $route() {},
    layerToSelectVector: {
      async handler(newValue) {
        if (newValue && Object.keys(newValue).length !== 0 && this.checkPolicyLimits) {
          const isDefaultService = this.app_user.defaultService.GLOBAL?.service_type === 'WS' && this.selectedVectorLayer.service_id !== this.layerToSelectVector.service_id && this.app_user.defaultService.GLOBAL?.service_details.some(item => item.service_id === this.serviceCardId);

          const fromRoute = this.$route.params.id.replace('-', '');
          const isSpecificDefaultService = this.app_user.defaultService[fromRoute]?.service_type && this.selectedVectorLayer.service_id !== this.layerToSelectVector.service_id && this.app_user.defaultService[fromRoute].service_details.some(item => item.service_id === this.serviceCardId) && this.map.specificDefaultService[fromRoute];

          const isSingleLayer = this.vectorLayers.filter(item => !item.layer_name.includes('ignore')).length === 1;

          if (isDefaultService || isSpecificDefaultService || isSingleLayer) {
            this.selectedVectorLayer = this.layerToSelectVector;

            if (isSpecificDefaultService && this.app_user.defaultService[fromRoute].service_details.some(item => item.service_id === this.serviceCardId && item.is_meteogram)) {
              this.$emit('toggle-meteogram-btn', true);
              this.$emit('check-specific-default-service');
            }

            if (Object.keys(this.selectedVectorLayer).length !== 0 && !this.map.OLmap.getLayers().getArray().slice(1).some(layer => layer.values_.service_id === this.layerToSelectVector.service_id) && this.app_user.defaultService.GLOBAL?.service_path !== 'map/Archive' && this.$route.params.id !== 'Archive') {
              this.mapEnabled = true;

              this.openVectorLayer(this.selectedVectorLayer);

              if (isSpecificDefaultService) {
                this.$emit('check-specific-default-service');
              }
            }
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions(['removeLayer', 'loadVectorLayer', 'removePreviousVectorLayer', 'checkOpacityServiceID', 'resetAnimationTime', 'storeWsServices', 'getWmtsArray', 'checkNewData', 'removeLayerByServiceID', 'getLayerExtent', 'getWmtsGDV']),
    async openVectorLayer(vector) {
      if (this.map.refreshIntervalID[vector.service_id] && !this.isRefreshing) {
        clearInterval(this.map.refreshIntervalID[vector.service_id]);
      }

      this.renderPlayer = false;
      this.$nextTick(() => {
        this.renderPlayer = true;
      });
      this.overlay = true;
      await this.setAnimationSteps();

      if (!this.isRefreshing && this.map.isAnimating.length == 0) {
        this.resetAnimationTime();
      }

      if (this.animationTimeArray.length > 0) {
        this.map.sliderOpacityValue = 60;

        const extent = await this.getLayerExtent([vector.ws_name, vector.layer_name, vector.layer_type, vector.service_id]);
        const vectorParams = {
          params: {
            GROUP: this.$route.params.id,
            LAYERS: `${vector.ws_name}:${vector.layer_name}`,
            TIME: this.animationTimeArray[this.animationStartIndex],
            STYLES: vector.layer_style,
            USERNAME: this.app_user.username,
            SERVICE_NAME: this.serviceName,
            PARAM_RANGE: this.map.vectorParamRange[this.serviceCardId]
          },
          TYPE: 'VECTOR',
          LAYER_SERVICE_ID: this.serviceCardId,
          EXTENT: extent
        };

        this.vectorLayers.forEach(layer => {
          if (layer.layer_name.includes('ignore')) {
            const index = this.table_chart.completeVectorChartItems.findIndex(item => item.service_id === layer.service_id);
            const obj = {
              layer_name: layer.layer_name,
              ws_name: layer.ws_name,
              service_id: layer.service_id,
              service_name: this.serviceName,
              minStep: this.minStep,
              maxStep: this.maxStep,
              isGraph: layer.is_graph,
              animationStep: this.animationStep,
              animationStepDateType: this.animationStepDateType
            };

            this.table_chart.completeVectorChartItems.some(item => item.service_id === layer.service_id) ?
              this.table_chart.completeVectorChartItems[index] = obj
              :
              this.table_chart.completeVectorChartItems.push(obj);
          }
        });

        const vectorToRemove = {
          service_id: this.serviceCardId,
          layer_name: `${vector.ws_name}:${vector.layer_name}`
        };
        this.checkOpacityServiceID(this.serviceCardId);

        if (!this.isRefreshing) {
          this.setRefreshInterval();
        }

        await this.removePreviousVectorLayer(vectorToRemove);

        await this.loadVectorLayer(vectorParams);

        if (this.$route.params.id === 'Forecast') {
          this.renderPlayer = false;
          this.$nextTick(() => {
            this.renderPlayer = true;
          });
        }

        const unit = dateUnit(this.animationStepDateType);
        const centerCoordinates = toLonLat(this.map.OLmap.getView().getCenter());
        const timeStamp = Date.now();
        this.$gtag.event('Intervallo temporale', {
          event_category: `${this.$route.params.id == undefined ? 'Home' : this.$route.params.id} - ${this.serviceName}`,
          event_label: `${timeStamp} - ${this.app_user.username} - ${this.app_user.crm_id} - ${getFormattedDate(new Date(), true, true)}`,
          value: `${getFormattedDate(new Date(vectorParams.params.TIME.split('/')[0]), true)} - ${getFormattedDate(new Date(vectorParams.params.TIME.split('/')[1]), true)} - MIN: ${getFormattedDate(new Date(this.minStep), true)} MAX: ${getFormattedDate(new Date(this.maxStep), true)} STEP: ${this.animationStep}${unit}`
        });
        this.$gtag.event('Centroide mappa', {
          event_category: `${this.$route.params.id == undefined ? 'Home' : this.$route.params.id} - Lat ${centerCoordinates[1].toFixed(4)} - Lon ${centerCoordinates[0].toFixed(4)}`,
          event_label: `${timeStamp} - ${this.app_user.username} - ${this.app_user.crm_id} - ${getFormattedDate(new Date(), true, true)}`,
          value: `${this.serviceName}: ${this.selectedVectorLayer.layer_name} - ${getFormattedDate(new Date(vectorParams.params.TIME.split('/')[0]), true)} - ${getFormattedDate(new Date(vectorParams.params.TIME.split('/')[1]), true)}`
        });
      }

      if (!this.isRefreshing) {
        this.reloadLegend();
      }

      this.overlay = false;
    },
    reloadLegend() {
      this.map.reloadLegend = true;
      this.$nextTick(() => {
        this.map.reloadLegend = false;
      });
    },
    async removePreviousLayer() {
      if (this.$route.params.id === 'Real-Time' && this.map.OLmap.getLayers().getArray().slice(1).map(layer => layer.values_.service_id === this.serviceCardId)) {
        await this.removeLayerByServiceID(this.serviceCardId);
      }
    },
    removeVectorLayer(vector) {
      this.renderArchiveDate = false;
      this.$nextTick(() => {
        this.renderArchiveDate = true;
      });

      if (this.$route.params.id === 'Forecast' || this.$route.params.id === 'Real-Time') {
        if (Object.keys(this.map.refreshIntervalID).includes(vector.service_id)) {
          clearInterval(this.map.refreshIntervalID[vector.service_id]);
          delete this.map.refreshIntervalID[vector.service_id];
        }
      }

      this.selectedVectorLayer = {};
      this.removeLayer(`${vector.ws_name}:${vector.layer_name}`);
      this.reloadLegend();
    },
    async initRefresh() {
      this.isRefreshing = true;
      this.rotateEffect = 'rotating';
      this.refreshBtnDisabled = true;
      setTimeout(() => {
        this.rotateEffect = '';
        this.refreshBtnDisabled = false;
      }, 2000);

      this.$emit('set-refresh-interval-overlay', true);

      const currentLayer = {
        policy_id: this.selectedVectorLayer.policy_id,
        service_id: this.selectedVectorLayer.service_id,
        service_type: 'WS'
      };
      await this.storeWsServices(currentLayer);

      if (this.policyLimits.type != 'static') {
        this.$emit('reset-min-max-step');
      }

      const index = this.$route.params.id === 'Forecast' ? 0 : 1;
      await this.checkNewData(['time', `${this.selectedVectorLayer.ws_name}:${this.selectedVectorLayer.layer_name}`, this.animationTimeArray.at(-1).split('/')[index]]);

      const [isNewData, newDataTime] = this.geoserver_data.isNewData;
      const limit = this.$route.params.id === 'Real-Time'
        ? this.animationLimitRangeArrayPolicy.at(-1)
        : updatedDateWithTimeStep(this.animationLimitFuture, this.animationLimitDateType, this.$route.params.id, new Date());

      if (isNewData && new Date(newDataTime) < new Date(limit)) {
        if (this.map.isAnimating.includes(this.serviceCardId)) {
          await this.setAnimationSteps();
        } else {
          await this.openVectorLayer(this.selectedVectorLayer);
        }
      }

      this.isRefreshing = false;
      this.$emit('set-refresh-interval-overlay', false);
    },
    setRefreshInterval() {
      const interval = timeToMilliseconds(this.refreshIntervalStep, this.refreshIntervalStepType);

      if (interval > 0) {
        this.map.refreshIntervalID[this.serviceCardId] = setInterval(async () => {
          await this.initRefresh();
        }, interval);
      }
    },
    async setAnimationSteps(userRangeDates) {
      const [archiveForecastInitialDate, archiveFinalDate] = userRangeDates || [];
      let isWmts = true;

      this.animationTimeArray = [];
      const nowDate = new Date();
      const startTime = archiveForecastInitialDate || nowDate;
      const step = this.$route.params.id === 'Real-Time' ? this.animationLimitPast : this.animationLimitFuture;
      const realtimeForecastStepDate = updatedDateWithTimeStep(step, this.animationLimitDateType, this.$route.params.id, nowDate);
      const realtimeForecastFinalDate = this.$route.params.id === 'Real-Time'
        ? Math.max(realtimeForecastStepDate, this.checkPolicyLimits.pastRangeTime)
        : Math.min(realtimeForecastStepDate, this.checkPolicyLimits.futureRangeTime);
      const limitDate = this.$route.params.id === 'Archive' ? archiveFinalDate : realtimeForecastFinalDate;
      let limitDateForecast = realtimeForecastFinalDate;
      let startTimeRounded = this.dailyData && this.$route.params.id === 'Archive' ? startTime : new Date(startTime).setHours(0, 0, 0, 0);
      let wmtsTimeRange = '';
      let dateToAdd = '';
      let rangeTime = '';

      const timeStepMs = timeToMilliseconds(this.animationStep, this.animationStepDateType);

      while (startTimeRounded < startTime && Math.abs(startTimeRounded - startTime) >= timeStepMs) {
        startTimeRounded = updatedDateWithTimeStep(this.animationStep, this.animationStepDateType, 'add_step', startTimeRounded);
      }

      if (this.$route.params.id === 'Archive' && startTimeRounded < startTime) {
        startTimeRounded = updatedDateWithTimeStep(this.animationStep, this.animationStepDateType, 'add_step', startTimeRounded);
      }

      if (this.$route.params.id === 'Archive') {
        wmtsTimeRange = `${new Date(archiveForecastInitialDate).toISOString()}/${new Date(archiveFinalDate).toISOString()}`;

        const wmtsParams = {
          layer: `${this.selectedVectorLayer.ws_name}:${this.selectedVectorLayer.layer_name}`,
          time: wmtsTimeRange,
          service_id: this.selectedVectorLayer.service_id,
          policy_id: this.selectedVectorLayer.policy_id
        };
        await this.getWmtsArray(wmtsParams);

        const xml_document = (new window.DOMParser()).parseFromString(this.geoserver_data.xml_string, 'text/xml');
        const xml_content = xml_document.getElementsByTagName('Domain')[0].textContent;
        const wmtsResultArray = xml_content ? xml_content.split(',') : [];

        if (wmtsResultArray.length > 0) {
          isWmts = true;
          this.noWmtsFeaturesFound = false;

          if (!this.dailyData) {
            rangeTime = updatedDateWithTimeStep(this.animationStep, this.animationStepDateType, 'remove_step', startTimeRounded);
            this.animationTimeArray.push(`${new Date(rangeTime).toISOString()}/${new Date(startTimeRounded).toISOString()}`);
          }

          dateToAdd = new Date(startTimeRounded).getTime();

          const [lowerBound, upperBound] = wmtsResultArray[0].split('--');
          const tableChartStartTime = updatedDateWithTimeStep(1, 'dynamic_minutes', 'remove_step', lowerBound);
          this.tableChartRangeDate = `${new Date(tableChartStartTime).toISOString()}/${upperBound}`;
        } else {
          isWmts = false;
          this.tableChartRangeDate = null;
          this.noWmtsFeaturesFound = true;
        }
      } else {
        wmtsTimeRange = this.$route.params.id === 'Real-Time'
          ? `${new Date(limitDate).toISOString()}/${new Date(startTimeRounded).toISOString()}`
          : `${new Date(startTimeRounded).toISOString()}/${new Date(limitDate).toISOString()}`;

        const wmtsParams = {
          layer: `${this.selectedVectorLayer.ws_name}:${this.selectedVectorLayer.layer_name}`,
          time: wmtsTimeRange,
          service_id: this.selectedVectorLayer.service_id,
          policy_id: this.selectedVectorLayer.policy_id
        };
        await this.getWmtsArray(wmtsParams);

        const xml_document = (new window.DOMParser()).parseFromString(this.geoserver_data.xml_string, 'text/xml');
        const xml_content = xml_document.getElementsByTagName('Domain')[0].textContent;
        const wmtsResultArray = xml_content ? xml_content.split(',') : [];

        if (wmtsResultArray.length > 0) {
          isWmts = true;
          this.noWmtsFeaturesFound = false;

          const index = this.$route.params.id === 'Forecast' && this.isAnimation ? 0 : 1;
          const primaryBound = wmtsResultArray[0].split('--')[index];
          limitDateForecast = new Date(wmtsResultArray[0].split('--')[1]).getTime();
          rangeTime = updatedDateWithTimeStep(this.animationStep, this.animationStepDateType, this.$route.params.id, startTimeRounded);
          const otherBoundForecast = rangeTime === new Date(primaryBound).getTime() ? new Date(this.maxStep).getTime() : rangeTime;
          const otherBoundRealTime = updatedDateWithTimeStep(this.animationStep, this.animationStepDateType, this.$route.params.id, primaryBound);
          this.animationTimeArray.push(this.$route.params.id === 'Real-Time'
            ? `${new Date(otherBoundRealTime).toISOString()}/${primaryBound}`
            : `${primaryBound}/${new Date(otherBoundForecast).toISOString()}`);

          dateToAdd = new Date(primaryBound).getTime();

          const [lowerBound, upperBound] = wmtsResultArray[0].split('--');
          const tableChartStartTime = updatedDateWithTimeStep(1, 'dynamic_minutes', 'remove_step', lowerBound);
          const tableChartEndTime = updatedDateWithTimeStep(this.policyLimits.future, this.policyLimits.type, 'add_step', lowerBound);
          // const tableChartEndTime = updatedDateWithTimeStep(1, 'dynamic_minutes', 'add_step', lowerBound); Prima era così
          this.tableChartRangeDate = this.$route.params.id === 'Real-Time'
            ? `${new Date(tableChartStartTime).toISOString()}/${upperBound}`
            : `${lowerBound}/${new Date(tableChartEndTime).toISOString()}`;
        } else {
          isWmts = false;
          this.tableChartRangeDate = null;
          this.noWmtsFeaturesFound = true;
        }
      }

      if (isWmts) {
        const limitCheckDate = (this.$route.params.id === 'Real-Time' || this.$route.params.id === 'Archive') ? limitDate : limitDateForecast;

        while (this.$route.params.id === 'Real-Time' ? (dateToAdd > limitDate) : (dateToAdd < limitCheckDate)) {
          dateToAdd = updatedDateWithTimeStep(this.animationStep, this.animationStepDateType, this.$route.params.id, dateToAdd);
          const addORremoveStep = this.$route.params.id === 'Forecast' ? 'add_step' : 'remove_step';
          const baseDate = this.$route.params.id === 'Forecast' && this.dailyData
            ? this.animationTimeArray.at(-1).split('/')[1]
            : dateToAdd;
          rangeTime = updatedDateWithTimeStep(this.animationStep, this.animationStepDateType, addORremoveStep, baseDate);

          if (this.$route.params.id === 'Real-Time' ? (dateToAdd >= limitDate) : (rangeTime <= limitDate)) {
            this.animationTimeArray.push(this.$route.params.id === 'Forecast'
              ? `${new Date(dateToAdd).toISOString()}/${new Date(rangeTime).toISOString()}`
              : `${new Date(rangeTime).toISOString()}/${new Date(dateToAdd).toISOString()}`);
          }
        }

        if (this.$route.params.id === 'Real-Time') {
          this.animationStartIndex = this.animationTimeArray.length - 1;
          this.animationTimeArray = this.animationTimeArray.reverse();
        }

        if (this.$route.params.id === 'Archive' && this.animationTimeArray.length === 0) {
          this.animationTimeArray.push(wmtsTimeRange);
        }

        this.table_chart.tableChartRangeDate[this.serviceCardId] = this.tableChartRangeDate;
        await this.getDataAvailability();
      }
    },
    async getDataAvailability() {
      const animationStepLength = this.animationTimeArray.length;

      if (animationStepLength > 0) {
        let time = new Date(this.animationTimeArray[0].split('/')[0]);
        time = time.setTime(time.getTime() - 60000);

        await this.getWmtsGDV([`${this.selectedVectorLayer.ws_name}:${this.selectedVectorLayer.layer_name}`, new Date(time).toISOString(), animationStepLength]);

        const xml_document = (new window.DOMParser()).parseFromString(this.geoserver_data.xml_string_GDV, 'text/xml');
        const xml_content = xml_document.getElementsByTagName('Domain')[0].textContent;
        let wmtsResultArray = xml_content ? xml_content.split(',') : [];

        const max = wmtsResultArray.find(time => new Date(time).getTime() > new Date(this.animationTimeArray[animationStepLength - 1].split('/')[1]).getTime());

        if (max) {
          const index = wmtsResultArray.indexOf(max);
          wmtsResultArray = wmtsResultArray.slice(0, index);
        }

        const checkStepIsEmpty = (lowerBound, upperBound, wmtsResultArray) => {
          return !wmtsResultArray.some(item => {
            const time = new Date(item).getTime();

            if (this.$route.params.id === 'Forecast') {
              return (lowerBound <= time) && (upperBound > time);
            }

            return (lowerBound < time) && (upperBound >= time);
          });
        };

        this.animationEmptySteps = [];

        if (animationStepLength !== wmtsResultArray.length) {
          this.animationTimeArray.forEach(step => {
            const lowerBound = new Date(step.split('/')[0]).getTime();
            const upperBound = new Date(step.split('/')[1]).getTime();

            if (checkStepIsEmpty(lowerBound, upperBound, wmtsResultArray)) {
              this.animationEmptySteps.push(step);
            }
          });
        }

        this.noVectorFeaturesFound(this.animationEmptySteps.includes(this.animationTimeArray[this.animationStartIndex]));
      }
    },
    noVectorFeaturesFound(bool) {
      this.noFeaturesFound = bool;
    },
    toggleMapBtn(selectedVectorLayer) {
      this.mapEnabled = !this.mapEnabled;

      if (!this.mapEnabled) {
        this.removeVectorLayer(selectedVectorLayer);
        this.renderPlayer = false;
      }
    },
    googleTag() {
      this.$gtag.event('Variabile', {
        event_category: `${this.$route.params.id == undefined ? 'Home' : this.$route.params.id}`,
        event_label: `${Date.now()} - ${this.app_user.username} - ${this.app_user.crm_id} - ${getFormattedDate(new Date(), true, true)}`,
        value: `${this.serviceName}: ${this.selectedVectorLayer.layer_name}`
      });
    }
  }
};
</script>

<style scoped>
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 1s linear infinite;
  -moz-animation: rotating 1s linear infinite;
  -ms-animation: rotating 1s linear infinite;
  -o-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;
}
</style>

